import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="App">
      {/* Background animations */}
      <div className="animated-stars">
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
      </div>

      {/* <div className="animated-moons">
          <div className="moon"></div>
        </div> */}

      <div className="animated-clouds">
        <div className="cloud"></div>
        <div className="cloud"></div>
        <div className="cloud"></div>
        <div className="cloud"></div>
      </div>

      <header className="App-header">
        <h1>StoryTime: Fast Story Creator</h1>
        <p>Create magical stories instantly! With AI-generated illustrations and voice narration, every custom story comes to life.</p>
        <button className="cta-button">Download Now</button>
      </header>

      <section className="features">
        <h2>Key Features</h2>
        <ul>
          <li>Custom Story Creation: Tailor stories to your preferences</li>
          <li>Instant Story Generation: Get a fresh new story with the press of a button</li>
          <li>AI-Generated Images: Stunning visuals created automatically for every story</li>
          <li>AI Voice Narration: Sit back and listen as the app reads your story aloud</li>
        </ul>
      </section>

      <section className="subscription">
        <h2>Flexible Subscriptions</h2>
        <p>Enjoy storytelling without limits! Choose from:</p>
        <ul>
          <li>Weekly Subscription: Auto-renewing for $2.99 per week</li>
          <li>Monthly Subscription: Auto-renewing for $9.99 per month</li>
        </ul>
      </section>
    </div>
  );
}

export default Home;