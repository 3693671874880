import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import { Link } from 'react-router-dom';
import HomePage from './pages/HomePage';
import TermsOfUse from './pages/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Cancellation from './pages/Cancellation';
import Contact from './pages/Contact';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cancellation" element={<Cancellation />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
      <footer className="App-footer">
        <p>Get started with your free trial today! <strong>Download the StoryTime app</strong> and let the adventures begin!</p>
        <button className="cta-button">Download Now</button>
        <p>
          <Link to="/">Home</Link> |
          <Link to="/terms-of-use">Terms of Use</Link> |
          <Link to="/privacy-policy">Privacy Policy</Link> |
          <Link to="/cancellation">Cancellation Policy</Link> |
          <Link to="/contact">Contact Us</Link>
          
        </p>
      </footer>
    </Router>
  );
}

export default App;

