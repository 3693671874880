import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import './Contact.css'
import firebase from "../Firebase";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [formStatus, setFormStatus] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRecaptcha = (value) => {
    if (value) {
      setRecaptchaVerified(true);  // User is verified as human
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!recaptchaVerified) {
      setFormStatus("Please verify that you are a human.");
      return;
    }

    // Submit form logic here
    console.log("Form Data:", formData);
    firebase.firestore().collection('contact_us').doc().set(formData).then(()=>{
        setFormStatus("Form submitted successfully!");

        // Reset form
        setFormData({
          name: "",
          email: "",
          message: "",
        });
        setRecaptchaVerified(false);
    })
  };

  return (
    <div className="contact-container">
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label>Message:</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            required
            className="form-input"
          ></textarea>
        </div>

        {/* Google reCAPTCHA */}
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_CLIENT_KEY}  // Replace with your site key
          onChange={handleRecaptcha}
        />

        <button type="submit" className="submit-button">Send</button>
        {formStatus && <p>{formStatus}</p>}
      </form>
    </div>
  );
};

export default ContactForm;
