import React from 'react';
import { Link } from 'react-router-dom';
import './privacy.css'
function PrivacyPolicy() {
    return (
        <body>
        <div>
            <section className="features">
                <h1>Privacy Policy</h1>
                <p><strong>Effective Date: September 30, 2024</strong></p>

                <p>Welcome to <strong>Clairvoyant Inc.</strong> ("<strong>We</strong>", "<strong>Us</strong>", "<strong>Company</strong>"). We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our <strong>website</strong> ("<strong>Site</strong>"), the <strong>StoryTime: Fast Story Creator</strong> application ("<strong>App</strong>"), and any related services (collectively, the "<strong>Services</strong>").</p>

                <p>By using our Services, you consent to the data practices described in this Privacy Policy, as well as our <a href="/terms-of-use">Terms of Service</a>. Please read them carefully before using the Services.</p>

                <h2>1. Information We Collect</h2>
                <p>We collect information to provide and improve our Services. This includes:</p>

                <h3>1.1 Personal Information You Provide</h3>
                <p>When you interact with our Services, you may provide personal information such as:</p>
                <ul>
                    <li>Name</li>
                    <li>Email address</li>
                    <li>Mailing address</li>
                    <li>Mobile number</li>
                    <li>Payment information (e.g., credit card details)</li>
                    <li>Financial details (e.g., income, expenses) for certain features</li>
                </ul>

                <p>This information is collected when you register for an account, fill out forms, contact us, or use certain features of the App or Site.</p>

                <h3>1.2 Cookies & Tracking Technologies</h3>
                <p>We use cookies and similar technologies to enhance your experience:</p>
                <ul>
                    <li><strong>Session Cookies</strong>: Temporary cookies that expire when you close your browser.</li>
                    <li><strong>Persistent Cookies</strong>: Stored on your device until you delete them. These help us remember your preferences on future visits.</li>
                </ul>
                <p>You can modify your cookie preferences through your browser settings. However, disabling cookies may limit certain functionalities of our Services.</p>

                <h3>1.3 Automatically Collected Information</h3>
                <p>We may collect information automatically when you interact with our Services, such as:</p>
                <ul>
                    <li>IP address</li>
                    <li>Browser type and device information</li>
                    <li>Pages viewed and interactions with the Site or App</li>
                    <li>Date and time of access</li>
                </ul>

                <h3>1.4 Third-Party Web Beacons & Buttons</h3>
                <p>Some features (e.g., social media “like” or “share” buttons) may collect information through third-party cookies and beacons, even if you do not interact with them. These third-party interactions are governed by the third parties' privacy policies.</p>

                <h2>2. How We Use Your Information</h2>
                <p>We use the information we collect to:</p>
                <ul>
                    <li>Operate and maintain our Services.</li>
                    <li>Personalize your experience and improve our features.</li>
                    <li>Communicate with you about promotions, updates, or administrative messages.</li>
                    <li>Analyze usage trends to enhance user experience.</li>
                </ul>
                <p>You can opt-out of receiving promotional emails by clicking the unsubscribe link at the bottom of any email, but you may still receive essential communications (e.g., account updates).</p>

                <h2>3. Third-Party Analytics & Advertising</h2>
                <p>We use third-party analytics tools such as <strong>Google Analytics</strong>, <strong>Firebase Analytics</strong>, and <strong>Apple App Store Analytics</strong> to understand how users interact with our Services. These tools may collect data through cookies and mobile advertising identifiers.</p>
                <p>For more information on Google Analytics, you can review <a href="https://policies.google.com/privacy">Google's privacy practices</a>. To manage your advertising preferences on mobile devices, you can adjust your settings in your device's settings menu.</p>

                <h2>4. Crash Reporting</h2>
                <p>To improve our App stability, we use services like <strong>Firebase Crash Reporting</strong> and <strong>Fabric Crashlytics</strong>. These tools collect data regarding app crashes (e.g., device type, error logs) to help us diagnose issues and enhance performance. You may opt-out of crash reporting via your app settings.</p>

                <h2>5. Your Privacy Choices</h2>
                <p>You have the following privacy options:</p>
                <ul>
                    <li><strong>Account Management</strong>: You can update or delete your personal information through your account settings.</li>
                    <li><strong>Email Preferences</strong>: You can unsubscribe from promotional emails at any time.</li>
                    <li><strong>Data Deletion</strong>: If you would like us to delete your personal information, please contact us at our <a href="https://story-time-app.com/contact">Contact Page</a>.</li>
                </ul>
                <p>Note that while we will process your requests promptly, we may retain information for legal, backup, or analytical purposes.</p>

                <h2>6. Children's Privacy</h2>
                <p>We do not knowingly collect personal information from children under 13. If we discover that a child under 13 has provided personal information, we will take steps to delete that data. If you believe that your child has provided us with personal information without consent, please contact us at our <a href="https://story-time-app.com/contact">Contact Page</a>.</p>

                <h2>7. Data Security</h2>
                <p>We take appropriate measures to protect your data, including encryption and limited access to our databases. However, no method of transmission over the internet or electronic storage is 100% secure. We cannot guarantee absolute security, and you use the Services at your own risk.</p>

                <h2>8. International Data Transfers</h2>
                <p>If you access our Services from outside the United States, please note that your data is processed and stored in the U.S., and is subject to U.S. laws. By using the Services, you consent to this transfer of information.</p>

                <h2>9. Third-Party Links</h2>
                <p>Our Services may include links to third-party websites, apps, or services. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to review their privacy policies before providing them with any personal information.</p>

                <h2>10. Mergers & Acquisitions</h2>
                <p>In the event that Clairvoyant Inc. undergoes a business transition such as a merger or acquisition, your information may be transferred to the new entity. We will ensure that any such transfer complies with applicable privacy laws.</p>

                <h2>11. Changes to This Privacy Policy</h2>
                <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for legal, operational, or regulatory reasons. Any changes will be posted on this page, and if they materially affect your rights, we will notify you by email or through the App.</p>

                <h2>12. Contact Us</h2>
                <p>If you have any questions or concerns about this Privacy Policy, please contact us at our <a href="https://story-time-app.com/contact">Contact Page</a>.</p>

                <p><strong>Last Updated</strong>: September 30, 2024</p>
            </section>
        </div>
        </body>
        
    );
}

export default PrivacyPolicy;
