import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css'
function TermsOfUse() {
    return (
        <div>
            <section className="features">
                <body>

                    <h1>Terms of Use Agreement</h1>
                    <p><strong>Effective Date: September 30, 2024</strong></p>

                    <p>Welcome to <strong>Clairvoyant Inc.</strong> ("<strong>Company</strong>", "<strong>We</strong>", "<strong>Us</strong>"). These <strong>Terms of Use</strong> (“<strong>Terms</strong>”) govern your access to and use of our website (the "<strong>Site</strong>"), the <strong>StoryTime: Fast Story Creator</strong> application ("<strong>App</strong>"), and related services available through the Site and App (the "<strong>Services</strong>").</p>

                    <p>By accessing or using our Site, downloading our App, or registering for our Services, you agree to comply with these Terms and all applicable laws. If you do not agree, you are prohibited from accessing or using the Site, App, or Services.</p>

                    <h2>1. Acceptance of Terms</h2>
                    <p>By using the Site, downloading the App, completing the registration process, or using the Services, you confirm that:</p>
                    <ul>
                        <li>You have read, understood, and agree to these Terms.</li>
                        <li>You are of legal age to enter into a binding contract with the Company.</li>
                        <li>You have the authority to enter into these Terms personally or on behalf of the entity you represent.</li>
                    </ul>
                    <p>If you do not agree with these Terms, you are not permitted to use the Services.</p>

                    <h2>2. Service Description</h2>
                    <p>The Services allow users to interact with generative AI models via chat. While the Services may provide information on third-party products, they are intended to assist conversations and not replace professional advice (e.g., legal, tax, financial advice).</p>

                    <p>The information provided is based on calculations and estimates generated by the Company for its purposes. These estimates may change and should not be relied upon without independent verification.</p>

                    <h2>3. Privacy and Data Usage</h2>
                    <p>Your privacy is important to us. By using our Services, you consent to the practices described in our <a href="/privacy-policy">Privacy Policy</a>. You also agree that:</p>
                    <ul>
                        <li>The Company may use your data, including aggregated, non-identifiable data, to improve Services and develop promotions.</li>
                        <li>We may store and process personal data across multiple countries in compliance with applicable laws.</li>
                    </ul>

                    <h2>4. Account Registration</h2>
                    <p>To use the Services, you must create an account with Clairvoyant. By doing so, you agree to:</p>
                    <ul>
                        <li>Provide accurate, complete, and current registration information.</li>
                        <li>Maintain the confidentiality of your account credentials, including your password.</li>
                        <li>Notify us immediately if you detect unauthorized use of your account.</li>
                    </ul>

                    <p>We reserve the right to verify your identity by requesting additional information (e.g., full address, social security number) or by verifying your data against third-party sources. If you fail to provide this information, we may suspend or terminate your account.</p>

                    <h2>5. Service Interruptions</h2>
                    <p>We may interrupt your access to the Services at any time for maintenance, updates, or other reasons beyond our control. The Company is not liable for any downtime, scheduled or unscheduled.</p>

                    <p>Your sole remedy for any interruption or failure of the Services is for us to attempt to repair or restore access to the Services.</p>

                    <h2>6. Mobile Usage</h2>
                    <p>If you use the Services via a mobile device, you are responsible for maintaining compatibility with the App, your mobile device, and network access. You are also responsible for any charges, fees, or updates required by your mobile service provider.</p>

                    <p>We do not guarantee the availability of telecommunication services or the accuracy of any information transmitted through mobile networks.</p>

                    <h2>7. Alerts and Notifications</h2>
                    <p>We may send automatic or voluntary alerts related to your account (e.g., password changes, subscription updates). Automatic alerts cannot be deactivated, but you can customize or deactivate voluntary alerts.</p>

                    <p>We do not guarantee the timely delivery or accuracy of any alerts. You agree that we are not liable for any delays, misdirected alerts, or reliance on inaccurate alert content.</p>

                    <h2>8. Your Responsibilities</h2>
                    <p>By submitting any content (e.g., data, usernames, passwords) to Clairvoyant through the Services, you grant us a limited license to use the content solely for providing the Services. You represent that you have the right to share such content and that it does not infringe on third-party rights.</p>

                    <h2>9. Intellectual Property</h2>
                    <p>All content provided by Clairvoyant (e.g., text, graphics, logos, software) is protected by copyright, trademark, and other laws. You are granted a limited, non-exclusive license to view and use the Services for personal, non-commercial purposes.</p>

                    <p>You may not distribute, reproduce, or use any content from the Services without prior written consent from Clairvoyant.</p>

                    <h2>10. Prohibited Uses</h2>
                    <p>You agree not to:</p>
                    <ul>
                        <li>Use automated tools (e.g., bots, spiders) to access or monitor the Services.</li>
                        <li>Reverse-engineer or decompile any part of the Services.</li>
                        <li>Use the Services for any unlawful purposes.</li>
                    </ul>
                    <p>Any violation of this section may result in the termination of your account.</p>

                    <h2>11. Payment and Subscriptions</h2>
                    <p>Payment for our Services is processed through the <strong>Apple App Store</strong>. Subscription options include auto-renewable monthly, three-month, or annual plans. All prices are listed in USD.</p>

                    <p>You may cancel your subscription at any time, but no refunds will be provided for unused portions of the subscription. Additional details regarding pricing and subscriptions can be found in the App or on our Site.</p>

                    <h2>12. Disclaimers</h2>
                    <p>The Services are provided on an “as-is” and “as-available” basis. Clairvoyant makes no warranties regarding the accuracy, reliability, or availability of the Services, including any content or information.</p>

                    <p>We disclaim any warranties of non-infringement or fitness for a particular purpose. In no event shall Clairvoyant be liable for any indirect, incidental, or punitive damages arising from your use of the Services.</p>

                    <h2>13. Indemnification</h2>
                    <p>You agree to indemnify and hold harmless Clairvoyant, its affiliates, officers, directors, and employees from any claims arising out of:</p>
                    <ul>
                        <li>Your use of the Services.</li>
                        <li>Your violation of these Terms.</li>
                        <li>Your violation of any third-party rights.</li>
                    </ul>

                    <h2>14. Modifications to Terms</h2>
                    <p>We reserve the right to modify these Terms at any time. Changes will be posted on the Site, and continued use of the Services constitutes acceptance of the updated Terms. You are encouraged to review these Terms regularly.</p>

                    <h2>15. Termination</h2>
                    <p>These Terms remain in effect until terminated by either party. You may close your account at any time by following the instructions on our <a href="/cancellation">Cancellation Page</a>.</p>

                    <p>Clairvoyant reserves the right to terminate your account if you violate these Terms.</p>

                    <h2>16. Governing Law</h2>
                    <p>These Terms are governed by the laws of the State of California, without regard to conflict of law provisions. All disputes will be resolved in the state or federal courts located in Orange County, California.</p>

                    <h2>17. Severability</h2>
                    <p>If any part of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in effect.</p>

                    <h2>18. Entire Agreement</h2>
                    <p>These Terms represent the entire agreement between you and Clairvoyant regarding the use of the Services and supersede all prior agreements.</p>

                    <p><strong>Last Updated</strong>: September 30, 2024</p>

                </body>
            </section>
        </div>
    );
}

export default TermsOfUse;
