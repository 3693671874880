import * as firebase from 'firebase';
//This is the configuration file for the react app. 
//Will change once we transition into production. This firebae project is for development. 
//On change adjust the google cloud functions and change the .firebasesrc file. 
const config = {
  apiKey: "AIzaSyCoVPXGneMJNaMrU7ZBiF24qUjv9gI1S88",
  authDomain: "autocrm-a9ea9.firebaseapp.com",
  projectId: "autocrm-a9ea9",
  storageBucket: "autocrm-a9ea9.appspot.com",
  messagingSenderId: "611171088941",
  appId: "1:611171088941:web:f0882183c529e231ec56a4",
  measurementId: "G-EGWK33XZC7"
  };

firebase.initializeApp(config);
firebase.analytics();




export default firebase;