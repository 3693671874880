import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css'

function Cancellation() {
    return (
        <div>
            <section className="features">
                <h1>Cancellation Policy</h1>
                <p>As per Apple Guidelines you can cancel any of your auto-renewable subscriptions in the Settings app. Open the Settings app, scroll down and select ‘iTunes & App Store’, Sign in if already not signed in, tap on your Apple ID, tap on ‘View Apple ID’ in the alert, tap on ‘Manage’ and finally Cancel your auto-renewable subscription.</p>
            </section>
        </div>
    );
}

export default Cancellation;
